import {json, type LoaderFunctionArgs} from '@remix-run/server-runtime';
import {Outlet, useLoaderData} from '@remix-run/react';

import type {Handle} from '@/types';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import useHandle from '@/hooks/useHandle';
import PageLayout from '@/components/shared/Page/PageLayout';
import PosHeader from '@/components/pos/section/Header/PosHeader';
import {pageLoader} from '@/utils/server/loader/pageLoader';
import type {LoaderResponse} from '@/utils/server/request.server';
import {contactFormLoader} from '@/pages/shopify.com/($locale)/pos/contact/loader.server';
import {useContactFormLoaderData} from '@/pages/shopify.com/($locale)/pos/contact/components/useContactFormLoaderData';

import {isRetailDriftbotAvailable} from './retailDriftBotLocaleRules';
import ContactFormModal from './contact/components/ContactFormModal';
import {HIDE_CONTACT_FORM_PATHS} from './paths';

export const loader = async (args: LoaderFunctionArgs) => {
  const baseData = (await pageLoader(args)) as LoaderResponse & {
    minervaUser: any;
  };

  const contactModalContent = await contactFormLoader(args);
  return json({...baseData, ...contactModalContent});
};

export const handle: Handle = {
  driftBot({site}) {
    return isRetailDriftbotAvailable(site.locale);
  },
};

export default function PosLayout() {
  const contactModalContent = useContactFormLoaderData();
  const {
    CustomHeader,
    CustomFooter,
    noHeader = false,
    noFooter = false,
  } = useHandle();

  const _customHeader = CustomHeader ? CustomHeader : PosHeader;
  const _customFooter = CustomFooter ? <CustomFooter /> : <Footer />;

  const header = noHeader ? () => null : _customHeader;
  const footer = noFooter ? null : _customFooter;

  /*
   * Sometimes we want to hide the main POS Contact Form Modal when we're using custom forms under /pos
   * Currently, marketing drives a lot of emails, post cards, and ads with #contact-sales in the URL.
   * For pages that use custom forms under /pos the user will techincally see the main ContactFormModal for a split second causing bad UX, tracking 2x instead of once, etc.
   */
  const {enPath} = useLoaderData<typeof loader>();
  const hideContactForm = enPath && HIDE_CONTACT_FORM_PATHS.includes(enPath);

  return (
    <PageLayout header={header} footer={footer} hasScrollRestoration>
      {!hideContactForm && <ContactFormModal {...contactModalContent} />}
      <Outlet />
    </PageLayout>
  );
}
