// Determines if Drift chat bot should be shown
export const isRetailDriftbotAvailable = (locale: string) => {
  return [
    'en',
    'en-GB',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-CA',
    'en-BE',
    'en-DK',
    'en-SG',
  ].includes(locale);
};
